import React, { useEffect } from "react";
import Header from "./Header/Header.jsx";
import Content from "./Content/Content.jsx";
import "./App.scss";

function App() {
  useEffect(() => {
    const midground = document.querySelector(".midground");
    const app = document.querySelector(".App");
    const end = document.querySelector(".end");

    let lastScrollTop = 0;
    let lastTransform = 0;

    const updateTransform = () => {
      const scrolled = window.scrollY;
      const diff = scrolled - lastScrollTop;
      lastTransform += diff * 0.5;

      midground.style.transform = `translateY(${lastTransform}px)`;

      const endTop = end.getBoundingClientRect().top + window.scrollY;
      const endHeight = end.getBoundingClientRect().height;
      const desiredHeight = Math.min(
        window.innerHeight + scrolled * 1.5,
        endTop + endHeight
      );

      app.style.height = `${desiredHeight}px`;

      lastScrollTop = scrolled;
    };

    const handleScroll = () => {
      if (window.innerWidth > 768) {
        // Check window width
        requestAnimationFrame(updateTransform);
      }
    };

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        // Reset transformations if the window is resized to less than 768 pixels wide
        midground.style.transform = "";
        app.style.height = "";
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="App">
      <div className="midground">
        <Header />
        <div className="content-wrapper">
          <Content />
          <div className="end"></div>
        </div>
      </div>
    </div>
  );
}

export default App;
