import React, { useState, useEffect } from "react";
import PlayButton from "./PlayButton.png";
import "./Video.scss";

export default function Video(props) {
  const [playing, setPlaying] = useState(props.playing);

  const handleClick = () => {
    props.onClick(); // Notify the parent component of the click event
    setPlaying(true);
  };

  useEffect(() => {
    setPlaying(props.playing);
  }, [props.playing]);

  const extractVideoId = (url) => {
    const parts = url.split("/embed/");
    return parts[1];
  };

  const videoId = extractVideoId(props.link);
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

  return (
    <div className={`video-component ${playing ? "playing" : ""}`}>
      <div className="thumbnail" onClick={handleClick}>
        {props.thumbnail ? (
          <img src={props.thumbnail} className="thumb" alt="Video thumbnail" />
        ) : (
          <img src={thumbnailUrl} className="thumb" alt="Video thumbnail" />
        )}

        <button className="play-button">
          <img src={PlayButton} />
        </button>
      </div>
      {playing && (
        <iframe
          className="video-iframe"
          src={props.link}
          title="Video Player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ border: "0" }}
        />
      )}
    </div>
  );
}
