import SheffGBreak from "./thumbails/sheffg_break.png";
import SleepyHit from "./thumbails/SleepyHit.png";
import Sleepy1999 from "./thumbails/Sleepy1999.png";
import AidenDemon from "./thumbails/AidenDemon.png";
import AidenIDWK from "./thumbails/AidenIDWK.png";
import Trippie from "./thumbails/trippie.png";

let videos = [
  {
    link: "https://www.youtube.com/watch?v=oEKgefVwM4M",
    title: "Jamario - Daily",
  },
  {
    link: "https://www.youtube.com/watch?v=zUwsZW93gSY",
    title: "Trippie Redd x NTWRK - Toy Commercial",
    thumbnail: Trippie,
  },
  {
    link: "https://www.youtube.com/watch?v=pHJqjE3y_W0&ab_channel=DroKenji",
    title: "Dro Kenji - VANISH",
  },
  {
    link: "https://www.youtube.com/watch?v=y1xZ_kAhjMc&ab_channel=SleepyHallowVEVO",
    title: "Sleepy Hallow - 2055",
  },
  {
    link: "https://www.youtube.com/watch?v=xjuu2JgmPCY&ab_channel=Jamario",
    title: "Jamario - Cope",
  },
  {
    link: "https://www.youtube.com/watch?v=Ih7DEc82myw&ab_channel=DroKenji",
    title: "Dro Kenji - USED2KNOW",
  },
  {
    link: "https://www.youtube.com/watch?v=yG28xiNlG3c&ab_channel=SoFaygo",
    title: "SoFaygo - Let's Lose Our Minds",
  },
  {
    link: "https://www.youtube.com/watch?v=P63iK7KbPaM&ab_channel=Jamario",
    title: "Jamario - Forget",
  },
  {
    link: "https://www.youtube.com/watch?v=Fwoi4106eRU&ab_channel=SHEFFGVEVO",
    title: "Sheff G - Drum Dummy",
  },
  {
    link: "https://www.youtube.com/watch?v=88jaOGIXnnc&list=PLnGxzNAIBe58ykLLP_dkyBCwTS3XyP0sw&index=11&ab_channel=SleepyHallow",
    title: "Sleepy Hallow - 1999",
    thumbnail: Sleepy1999,
  },
  {
    link: "https://www.youtube.com/watch?v=YqhCgEFuHXQ&ab_channel=SheffG",
    title: "Sheff G - Break From It",
    thumbnail: SheffGBreak,
  },
  {
    link: "https://www.youtube.com/watch?v=7-aP9PfRWa8&ab_channel=347AIDAN",
    title: "347aidan - IDWK",
    thumbnail: AidenIDWK,
  },
  {
    link: "https://www.youtube.com/watch?v=Fh24-nQzBX0&ab_channel=347AIDAN",
    title: "347aidan - DEMONS & MONSTERS",
    thumbnail: AidenDemon,
  },
  {
    link: "https://www.youtube.com/watch?v=9HF_fxhHAAI&ab_channel=SheffG",
    title: "Sheff G - No Remorse",
  },
  {
    link: "https://www.youtube.com/watch?v=743tY2YiRkc&list=PLnGxzNAIBe58ykLLP_dkyBCwTS3XyP0sw&index=23&ab_channel=SleepyHallow",
    title: "Sleepy Hallow - Hit Man",
    thumbnail: SleepyHit,
  },
  {
    link: "https://www.youtube.com/watch?v=NTCn02VMVqQ&ab_channel=SHEFFGVEVO",
    title: "Sheff G - Bad Breed",
  },
];

function transformURLs(url) {
  let video_id = url.split("v=")[1];
  let ampersandPosition = video_id.indexOf("&");
  if (ampersandPosition !== -1) {
    video_id = video_id.substring(0, ampersandPosition);
  }

  return `https://www.youtube.com/embed/${video_id}`;
}

videos = videos.map((video) => {
  return {
    ...video,
    link: transformURLs(video.link),
  };
});

export default videos;
