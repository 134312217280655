import React from "react";
import "./Header.scss";

import AyloHeader from "./aylo-header.png";

import Instagram from "./Icons/instagram.png";
import Vimeo from "./Icons/vimeo.png";
import Twitter from "./Icons/twitter.png";
import Email from "./Icons/email.png";

export default class Header extends React.Component {
  render() {
    return (
      <div id="header">
        <div id="icon-row">
          <a href="https://www.instagram.com/aylo_edit/">
            <img src={Instagram} alt="Instagram" />
          </a>
          <a href="https://vimeo.com/user99721043">
            <img src={Vimeo} alt="Vimeo" />
          </a>
          <a href="https://twitter.com/ryanselkirk">
            <img src={Twitter} alt="Twitter" />
          </a>
          <a href="mailto:ryandylanselkirk@gmail.com">
            <img src={Email} alt="Email" />
          </a>
        </div>
        <img src={AyloHeader} alt="AyloHeader" className="header-title" />
      </div>
    );
  }
}
