import React, { useState } from "react";
import videos from "./Videos";
import "./Content.scss";
import Video from "../Video/Video";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Content() {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);

  const handleVideoClick = (index) => {
    setCurrentVideoIndex(index);
  };

  return (
    <Container id="content">
      <Row>
        {videos.map((video, index) => (
          <Col key={video.title} xs={12} sm={6} className="col">
            <div
              className={`video-container ${
                index === currentVideoIndex ? "playing" : ""
              }`}
            >
              <Video
                link={video.link}
                title={video.title}
                thumbnail={video.thumbnail}
                onClick={() => handleVideoClick(index)}
                playing={index === currentVideoIndex}
              />
              <h2>{video.title}</h2>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
